<template>
    <v-row>

      <v-col cols="12" lg="12" sm="12" align="center">
        <v-alert color="primary" dark icon="mdi-account-credit-card-outline" border="left" prominent>
          <div class="font-weight-bold">
            Paga con tu tarjeta preferida desde cualquier lugar!
          </div>
        </v-alert>
      </v-col>

      <v-col align="center">
        <v-progress-circular
          :size="200"
          :width="8"
          color="primary"
          indeterminate
          v-if="url == null || url == ''"
        >
          <b>Espere un momento!</b>
        </v-progress-circular>

        <iframe :src="url" style="width:100%; height:800px;  margin: 0px !important; border: 0px !important;"></iframe>

        <a :href="url" target="_blank" v-if="url!=null && url!=''">Abrir en una ventana nueva</a>
      </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    order: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      internalLoading: false,
      url: ''
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    getPayment() {
      const ocId = this.order.ocId;

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/mitec/orders/${ocId}`;
      } else {
        url += `/public/mitec/orders/${ocId}`;
      }

      this.internalLoading = true;

      axios
        .post(url, {})
        .then((res) => {
          // SE OBTIENE EL CUERPO DE LA RESPUESTA DE MANERA SEGURA
          const body = _.get(res, "data.data", {});
          
          if(body.url != undefined && body.url != null && body.url != ''){
            this.url = body.url;
          }
        })
        .catch((err) => {
          let msg = _.get(err, 'response.data.message', err.toString());

          this.$dialog({
            title: "Error",
            text: msg,
          }).then(() => {
            // DOES NOTHING
          });
        }).finally(()=>{
          this.internalLoading = false;
        })
    },
  },
};
</script>